const axios = require('axios');

function kebabCaseToCamel(str) {
    return str.replace( /(-\w)/g, (matches) => matches[1].toUpperCase())
}

class API {
    constructor(){
        this.url = 'https://tirage-verset.vasesdhonneur.org/api';
        this.endpoints = {}
    }
    /**
     * Create and store a single entity's endpoints
     * @param {A entity Object} entity
     */
    createEntity(entity) {
        /**
         * If there is a - in the entity.name, then change it
         * to camelCase. E.g
         * ```
         * myApi.createEntity({ name : 'foo-bar'})
         * myApi.endpoints.fooBar.getAll(...)
         */

        const name = kebabCaseToCamel(entity.name)
        this.endpoints[name] = this.createBasicCRUDEndpoints(entity)
    }

    createEntities(arrayOfEntity) {
        arrayOfEntity.forEach(this.createEntity.bind(this))
    }
    /**
     * Create the basic endpoints handlers for CRUD operations
     * @param {A entity Object} entity
     */
    createBasicCRUDEndpoints( { name } ) {
        var endpoints = {}

        const resourceURL = `${this.url}/${name}`

        endpoints.getAll = ( config={} ) => axios.get(resourceURL, config)

        endpoints.getOne = ({ id }, config={}) =>  axios.get(`${resourceURL}/${id}`, config)

        endpoints.create = (toCreate, config={}) =>  axios.post(resourceURL, toCreate, config)

        endpoints.update = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate, config)

        endpoints.patch  = ({id}, toPatch, config={}) => axios.patch(`${resourceURL}/${id}`, toPatch, config)

        endpoints.delete = ({ id }, config={}) => axios.delete(`${resourceURL}/${id}`, config)

        return endpoints

    }

    login(username, password) {
        const resourceURL = `${this.url}/login_check`;

        return axios.post(resourceURL, {
            username: username,
            password: password
        })
            .then(function (response) {
                if (response.status === 200 && typeof response.data !== "undefined") {
                    return response.data
                }
            })
            .catch(function (err) {
                console.log(err)
                return {
                    "contextStatus": 900,
                    "response": err.response,
                    //"rootCause": err.response.violations[0].message
                }
            });
    }

    register(user) {
        const resourceURL = `${this.url}/users`;

        return axios.post(resourceURL, {
            email: user.email,
            name: user.name,
            firstName: user.firstName,
            country: user.country,
            city: user.city,
            password: user.password
        })
            .then(function (response) {
                if (response.status === 201 && typeof response.data !== "undefined") {
                    return response.data
                }
                console.log(response);
            })
            .catch(function (err) {
                console.log(err)
                return {
                    "contextStatus": 900,
                    "response": err.response,
                    // "rootCause": err.response.violations[0].message
                }
            });
    }

    userMe(email) {
        const resourceURL = `${this.url}/user/${email}`;

        return axios.get(resourceURL, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        })
            .then(function (response) {
                if (response.status === 200 && typeof response.data !== "undefined") {
                    return response.data
                }
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    setVerse(id) {
        const resourceURL = `${this.url}/user/verse/add/${id}`;

        return axios.get(resourceURL, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        })
            .then(function (response) {
                if (response.status === 200 && typeof response.data !== "undefined") {
                    return response.data
                }
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


}

export default API
