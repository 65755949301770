import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router';
import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
import store from './store';
import VueJwtDecode from 'vue-jwt-decode'
import vueCountryRegionSelect from 'vue-country-region-select'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast);
Vue.use(VueJwtDecode);
Vue.use(vueCountryRegionSelect);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
