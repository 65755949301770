<template>
    <div id="login">
        <div class="container">
            <div id="login-row" class="row justify-content-center align-items-center">
                <div id="login-column" class="col-md-6">
                    <img id="logovh" src="../assets/logo_vh_for_colors.png" alt="">
                    <div id="login-box" class="col-md-7 m-auto">
                        <form id="login-form" class="form" action="" method="post">
                            <h3 class="text-center mb-3">Connexion</h3>
                            <div class="form-group mb-3">
                                <label for="username">Adresse Email:</label><br>
                                <input v-model="input.email" type="email" name="username" id="username" class="form-control">
                            </div>
                            <div class="form-group mb-3">
                                <label for="password">Mot de passe:</label><br>
                                <input v-model="input.password" type="password" name="password" id="password" class="form-control">
                            </div>
                            <div class="form-group mt-3">
                                <div @click="login" class="btn btn-primary">Connexion</div>
                            </div>
                            <div id="register-link" class="mt-3">
                                <router-link class="registration" :to="{name: 'register'}">Pas encore inscrit ? Je m'inscris</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from '../API/Api.js'
    export default {
        name: "Login",
        data() {
            return {
                input: {
                    email: "",
                    password: ""
                }
            }
        },
        mounted() {
            console.log( localStorage.getItem('accessToken') );
            if ( localStorage.getItem('accessToken') ) {
                this.$router.replace({ name: "dashboard" });
            }
        },
        methods: {
             login() {
                if(this.input.username !== "" && this.input.password !== "") {
                    console.log('OK');
                    const myApi = new API();
                    myApi.login(this.input.email, this.input.password).then( response => {
                        if (typeof response.contextStatus !== "undefined" && response.contextStatus === 900) {
                            this.$toast.top(this.errorTranslator(response.response.data.violations[0].message));
                            return null;
                        } else {
                            console.log(response)
                            // this.$emit("authenticated", true);

                            localStorage.setItem('accessToken', response.token);
                            console.log(this.$store);
                            this.$store.commit('loginStop', null);
                            this.$store.commit('updateAccessToken', response.token);

                            this.$router.replace({name: "dashboard"});
                        }

                    }).catch( () =>{
                        this.$toast.top('Email ou mot de passe incorrect');
                    });

                } else {
                    this.$toast.top('Email ou mot de passe incorrect');
                }
            }
        }
    }
</script>

<style scoped>
    #login {
        background: rgba(15, 115, 109, 1);
        background-size: cover;
        height: 100vh;
    }


</style>
