<template>
    <div class="user-dashboard">
        <h1><span id="levantein">Mon </span>verset 2022</h1>
        <h4 class="ticker">vous rencontrez un soucis pour tirer votre verset ? <span><a class="goldlink" href="https://helpdesk.vasesdhonneur.org/index.php?a=add">Cliquez ici</a></span> </h4>

        <div class="row entity even">
            <div class="col-xs-12 col-md-12 claim-section">
                <div v-if="this.$store.state.verse.current === null">
                    <h1>Mon verset de l'année</h1>
                    <p class="user-cta-text">
                        Pour obtenir votre verset de l’année, <br />veuillez cliquer sur le bouton ci dessous suivant:
                    </p>
                    <button @click="userSetVerse" type="button" class="launch" data-dismiss="modal">mon verset de l'année</button>
                </div>
                <div v-else>
                    <!--                    <img class="img-responsive" src="../assets/assets/img/versets/MON VERSET-&#45;&#45;110.png" alt="">-->
                    <img class="img-responsive" :src="verseImg" alt="Mon verset 2022">
                    <div class="mt-5">
                        <a class="btn btn-lg btn-primary" :href="verseImg" download="MonVerset2022">Télécharger mon verset</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="myDevo">
            <h1> <a class="goldlink" href="https://mydevo.net">Télécharger MyDevo</a></h1>
            <div class="row entity even">
                <div class="col-xs-12 col-md-12 claim-section">
                    <a href="https://mydevo.net">
                        <img class="img-responsive" src="../assets/bannersPubDevo1.png" alt="mydevo">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../API/Api";

    export default {
        name: "YearlyVerse",
        data() {
            return {
                verseImg: ''
            }
        },
        props: ['user'],
        methods: {
            userSetVerse() {
                const myApi = new API();
                myApi.setVerse(this.user.id).then( response => {
                    console.log(response);
                    if (typeof response.data !== "undefined") {
                        console.log('OK');
                        this.$store.commit('currentVerseSetting', response.data)
                        this.verseImg = this.$store.state.verse.current.path;
                    }
                });
            },
            getImgUrl(pet) {
                var images = require.context('../assets/', false, /\.jpg$/)
                return images('./' + pet)
            }
        },
        mounted() {
            setTimeout(() =>{
                console.log(this.$store.state.verse.current);
                if (typeof this.$store.state.verse.current !== "undefined" && this.$store.state.verse.current !== null) {
                    console.log (this.$store.state.verse);
                    this.verseImg = this.$store.state.verse.current[0].path;
                } else {
                    console.log(this.$store.state);
                }
            }, 3000)

        }
    }
</script>

<style scoped>
.goldlink {
    text-decoration: none !important;
}

.goldlink:hover {
    color: rgba(209, 195, 68, 1) !important;
    transition: linear .7s;
}

.ticker {
    animation: marquee 30s infinite;
}

/* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 270px) and (max-device-width : 480px) {
        #levantein {
            border: none;
            color: rgba(209, 195, 68, 1);
        }
        .user-dashboard h1 {
            font-weight: normal;
        }
    }
</style>
