<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'App',
  components: {
  },
  methods: {
    ...mapActions([
      'fetchAccessToken'
    ]),
  },
  data() {
    return {
      transitionName: 'slide-left'
    }
  },
  created() {
    // this.fetchAccessToken('OK');
  },
}
</script>

<style lang="scss">
  @import './assets/eidos.css';
  @import './assets/custom-vars.scss';
</style>

<style>
a {
  color: #0f736d !important;
}
.btn-primary {
  color: #d1c344  !important;;
  background-color: #0f736d  !important;;
  border-color: #0f736d  !important;
  font-weight: bold !important;
}
#app {
  font-family: Mulish, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.registration {
  color: #635858 !important;
  font-weight: bold;
}

  .form-group {
    text-align: left;
  }

#register-link {
  text-align: left;
}

#register-link a {
  text-decoration: none;
}
</style>
