import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loggingIn: false,
        loginError: null,
        loginSuccessful: false,
        accessToken: null,
        verse: {
            current: null,
            previous: []
        },
        user: {

        }
    },
    mutations: {
        loginStart: state => state.loggingIn = true,
        loginStop: (state, errorMessage) => {
            state.loggingIn = false;
            state.loginError = errorMessage;
            state.loginSuccessful = !errorMessage;
        },
        updateAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
        },
        currentVerseSetting: (state, verse) => {
            state.verse.current = verse;
        },
        currentUserSetting: (state, user) => {
            state.user.current = user;
        },
    },

    actions:{
        fetchAccessToken({ commit }) {
            commit('updateAccessToken', localStorage.getItem('accessToken'));
        }
    },
})
