<template>
    <div class="row">
        <header class="d-flex">
            <div class="col-md-7">
                <nav class="navbar-default pull-left">
                    <div class="navbar navbar-expand-lg navbar-light">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </nav>
            </div>

            <div class="col-md-5">
                <div class="header-rightside">
                    <ul class="list-inline header-top float-right">
                        <li class="d-xs-none"><a href="#"><i class="fa fa-search" aria-hidden="true"></i></a></li>
                        <li>
                            <span>{{user.firstName}} {{user.name}}</span> | <a href="#" @click="logout">Déconnexion</a>
                        </li>
                        <li class="dropdown">
                            <!--<a id="userMenu" class="dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <b class="caret"></b></a>-->
                            <ul class="dropdown-menu" aria-labelledby="userMenu">
                                <li>
                                    <div class="navbar-content">
                                        <span>Gasparien de Jesus</span>
                                        <p class="text-muted small">
                                            G.dejesus@vhck.org
                                        </p>
                                        <div class="divider">
                                        </div>
                                        <a href="#" class="view btn-sm active">Mon Profil</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
    export default {
        name: "HeaderApp",
        props: ['user'],
        methods: {
            logout () {
                localStorage.removeItem('accessToken');
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>
    .navbar-default {
        background: transparent;
    }
    .float-right {
        float: right;
    }
    .navbar-toggler {
        display: none;
    }
    #userMenu {
        visibility: hidden;
    }
</style>
