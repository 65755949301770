<template>
    <div id="login">
        <div class="container">
            <div id="login-row" class="row justify-content-center align-items-center">
                <div id="login-column" class="col-md-6">
                    <img id="logovh" src="../assets/logo_vh_for_colors.png" alt="">
                    <div id="login-box" class="col-md-7 m-auto">
                        <form id="login-form" class="form" @submit="registration" method="post">
                            <h3 class="text-center mb-3">Inscription</h3>
                            <div class="form-group mb-3">
                                <label for="name">Nom et prénoms:</label><br>
                                <input required v-model="input.name" type="text" name="name" id="name" class="form-control">
                            </div>
                            <div class="form-group mb-3">
                                <label for="country">Pays:</label><br>
                                <country-select required :autocomplete="true" v-model="input.country" :country="input.country" topCountry="CI" id="country" class="form-control"/>
                            </div>
                            <div class="form-group mb-3">
                                <label for="city">Ville:</label><br>
                                <input required v-model="input.city" type="text" name="city" id="city" class="form-control">
                            </div>
                            <div class="form-group mb-3">
                                <label for="email">Adresse Email:</label><br>
                                <input required v-model="input.email" type="email" name="email" id="email" class="form-control">
                            </div>
                            <div class="form-group mb-3">
                                <label for="password">Mot de passe:</label><br>
                                <input required v-model="input.password" type="password" name="password" id="password" class="form-control">
                            </div>
                            <div class="form-group mb-3">
                                <label for="passwordconfirm">Retaper votre mot de passe:</label><br>
                                <input required v-bind:class="correctPass" @change="equalToPassword" v-model="input.confirmPassword" type="password" name="passwordconfirm" id="passwordconfirm" class="form-control">
                            </div>
                            <div class="form-group mt-5">
                                <!--       <a @click="registration" :disabled="correctPass === 'bad-pass'" class="btn btn-primary" :class="correctPass">Inscription</a>-->
                                <input type="submit" class="btn btn-primary" :disabled="correctPass === 'bad-pass'" value="Inscription">
                            </div>

                            <div id="register-link" class="text-start mt-3">
                                <router-link class="registration" :to="{name: 'login'}">Retourner à la connexion</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from '../API/Api.js'
    export default {
        name: "Login",
        components: {
        },
        data() {
            return {
                input: {
                    name: "",
                    email: "",
                    password: "",
                    country: "",
                    city: "",
                    confirmPassword: "",
                },
                correctPass: ''
            }
        },
        watch: {

        },
        mounted() {
            console.log( localStorage.getItem('accessToken') );
            if ( localStorage.getItem('accessToken') ) {
                this.$router.replace({ name: "dashboard" });
            }
        },
        methods: {
            errorTranslator(error) {
                let trans;
                switch (error) {
                    case 'This value is already used.':
                        trans = 'Cet email a déjà été utilisé. Veuillez-vous connecter ou réinitialiser votre mot de passe';
                        break;

                    default:
                        trans = error;
                        break;

                }

                return trans;
            },
            equalToPassword() {
                if (this.input.password !== this.input.confirmPassword) {
                    console.log('Not OK yet', this);
                    this.correctPass = 'bad-pass'
                    this.$toast.top('Le mot de passe saisi n\'est pas conforme');
                } else {
                    this.correctPass = ''
                }
            },
            registration(e) {
                e.preventDefault();
                if(this.input.username !== "" && this.input.password !== "") {
                    console.log('OK');
                    const myApi = new API();

                    console.log(this.input);
                    console.log (this.input.name.split(' ')[1]);
                    let userRegistrationData = this.input;

                    userRegistrationData.firstName = this.input.name.split(' ')[1];
                    userRegistrationData.name = this.input.name.split(' ')[0];

                    myApi.register(userRegistrationData).then( response => {
                        console.log(response)
                        if (typeof response.contextStatus !== "undefined" && response.contextStatus === 900) {
                            this.$toast.top(this.errorTranslator(response.response.data.violations[0].message));
                            return null;
                        } else {
                            // localStorage.setItem('accessToken', response.token);
                            console.log (this.$store);
                            this.$store.commit('currentUserSetting', response);

                            // Do a login
                            console.log ('OT');
                            const mySubApi = new API();
                            console.log(mySubApi);
                            mySubApi.login(this.input.email, this.input.password).then( response => {
                                console.log(response);
                                this.$store.commit('loginStop', null);

                                localStorage.setItem('accessToken', response.token);
                                this.$store.commit('updateAccessToken', response.token);

                                this.$router.replace({ name: "dashboard" });
                            }).catch( (error) =>{
                                console.log(error);
                                this.$toast.top('Email ou mot de passe incorrect');
                            });
                        }

                    }).catch( (err) =>{
                        console.log(err);
                        this.$toast.top('Email ou mot de passe incorrect', err);
                    });

                } else {
                    this.$toast.top('Email ou mot de passe incorrect');
                }
            }
        }
    }
</script>

<style scoped>
    a.bad-pass {
        background-color: #6c757d !important;
        border-color: #6C757D !important;
        color: #CCC !important;
        cursor: not-allowed;
    }

    #login {
        background: rgba(15, 115, 109, 1);
        background-size: cover;
        height: 100vh;
    }

    .bad-pass {
        border: 1px solid mediumvioletred;
        background: rgb(175 61 61 / 62%);;
    }


    #register-link {
        text-align: left;
    }
</style>
