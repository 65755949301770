<template>
    <div class="col-md-2 col-sm-1 hidden-xs display-table-cell v-align box" id="navigation">
        <div class="logo text-center">
            <router-link :to="{name: 'dashboard'}">
                <img src="../assets/logo_vh_for_colors.png" alt="merkery_logo" class="m-auto d-xs-none d-sm-none d-lg-block d-md-block">
                <img src="../assets/logo_vh_for_colors.png" alt="vh_logo" class="m-auto d-lg-none d-md-none d-xs-block d-sm-block circle-logo">
            </router-link>
        </div>
        <div class="navi">
            <ul>
                <li class="active">
                    <router-link :to="{name: 'dashboard'}">
                        <i class="fa fa-home" aria-hidden="true"></i><span class="hidden-xs hidden-sm">Mon espace</span>
                    </router-link>
                </li>
               <!-- <li>
                    <router-link :to="{name: 'my-verse'}">
                        <i class="fa fa-bible" aria-hidden="true"></i><span class="hidden-xs hidden-sm">Mon verset</span>
                    </router-link>
                </li>
                <li>
                    <a href="#"><i class="fa fa-folder-open" aria-hidden="true"></i><span class="hidden-xs hidden-sm">Mes versets antérieurs</span></a>
                </li>-->
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SidebarNav"
    }
</script>

<style>

    .navi {
        min-height: 155vh;
    }
    .navi ul {
        padding-left: 0;
        text-align: left;
    }

    .navi a {
        color: #ffffff !important;
        display: block;
        font-size: 17px;
        font-weight: 500;
        padding: 15px 20px;
        text-decoration: none;
    }

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 270px) and (max-device-width : 767px) {
        #navigation {
            display: none;
        }
        .button.navbar-toggler {
            display: none;
        }
    }
</style>
