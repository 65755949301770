import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../components/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../components/Register.vue')
    },
    {
        path: '/home',
        name: 'dashboard',
        component: () => import('../components/Dashboard.vue')
    },
    {
        path: '/my-verse',
        name: 'my-verse',
        component: () => import('../components/MyVersePage.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
