<template>
    <div class="container-fluid display-table">
        <div class="row display-table-row">
            <div class="col-md-2 col-sm-1 hidden-xs display-table-cell v-align box" id="navigation">
                <sidebar-nav></sidebar-nav>
            </div>
            <div class="col-md-10 col-sm-11 display-table-cell v-align">
                <header-app v-bind:user="user"></header-app>

                <yearly-verse v-bind:user="user"></yearly-verse>
            </div>
        </div>
    </div>
</template>

<script>
    import SidebarNav from "./SidebarNav";
    import HeaderApp from "./HeaderApp";
    import YearlyVerse from "./YearlyVerse";
    import jwt_decode from 'jwt-decode';
    import API from "../API/Api";
    export default {
        name: "Dashboard",
        components: {YearlyVerse, HeaderApp, SidebarNav},
        data() {
            return {
                user: {
                    id: 0,
                    name: "",
                    firstName: "",
                    email: "",
                    verse: [],
                }
            }
        },
        mounted() {
            if ( localStorage.getItem('accessToken') ) {
                console.log(this);
                var decode = jwt_decode( localStorage.getItem('accessToken') );
                this.userInfo(decode.email);

            }
        },
        methods: {
            userInfo(email) {
                const myApi = new API();
                myApi.userMe(email).then( response => {
                    if (typeof response.data !== "undefined") {
                        this.user = {
                            id: response.data.id,
                            name: response.data.name,
                            firstName: response.data.firstName,
                            email: response.data.email,
                            country: response.data.country,
                            verse: response.data.verse
                        };
                        console.log(response);
                        if ( response.data.verse.length > 0 ) {
                            this.$store.commit('currentVerseSetting', response.data.verse)
                        }

                        console.log (this.$store.state.verse.current)
                    }
                });
            },

        }
    }
</script>

<style scoped>

</style>
